import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import { JSONParse } from '../../../../../utils/json';
import { TableCellBodyStyled } from '../return-details.style';
import currencyFormatter from '../../../../../utils/currencyFormatter';
import { toTitleCase } from '../../../../../utils/toTitleCase';

const SaleItemRow = ({ saleItem, sale }) => {
  const {
    name, quantity, packSize, productDiscount, meta, unitCost, total, promoValue, product: { erpVersion: { category } }
  } = saleItem;
  const { vatStatus } = category || {};
  const { quantity_in_stock: quantityInStock = 'N/A', discount_on_returns: discountOnReturns = 0, uom } = JSONParse(meta);
  const getDiscount = () => {
    const result = productDiscount / (total / 100);
    return result || 0;
  };

  const { meta: saleMeta } = sale;
  const vat_percent = JSONParse(saleMeta)?.sale_vat?.vat_percent || '0';

  const getPromo = () => {
    const result = promoValue;
    return result || 0;
  };
  let aggregatedDiscount = unitCost * quantity - productDiscount;
  if (discountOnReturns) {
    aggregatedDiscount -= discountOnReturns;
  }

  return (
    <TableRow>
      <TableCellBodyStyled>{name}</TableCellBodyStyled>
      <TableCellBodyStyled>{packSize}</TableCellBodyStyled>
      <TableCellBodyStyled>
        {String(quantityInStock).padStart(2, '0')}
      </TableCellBodyStyled>
      <TableCellBodyStyled>
        {String(quantity).padStart(2, '0')}
      </TableCellBodyStyled>
      <TableCellBodyStyled>{currencyFormatter(unitCost)}</TableCellBodyStyled>
      <TableCellBodyStyled>
        {`${getDiscount()}%`}
      </TableCellBodyStyled>
      <TableCellBodyStyled>
        {uom ? toTitleCase(uom) : 'N/A'}
      </TableCellBodyStyled>
      <TableCellBodyStyled>
        {vatStatus === 'YES' ? vat_percent : 0}
      </TableCellBodyStyled>
      <TableCellBodyStyled>
        {currencyFormatter(aggregatedDiscount)}
      </TableCellBodyStyled>
      <TableCellBodyStyled>
        {`${getPromo()}%`}
      </TableCellBodyStyled>
    </TableRow>
  );
};

SaleItemRow.propTypes = {
  saleItem: PropTypes.instanceOf(Object),
  sale: PropTypes.instanceOf(Object).isRequired
};

SaleItemRow.defaultProps = {
  saleItem: {}
};

export default SaleItemRow;
